import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { Button, Form, Image } from "react-bootstrap";

const ERROR_REDIRECT_URL = "https://awakeboards.com/404"
const SUCCESS_REDIRECT_URL = "https://awakeboards.com/pages/successful-password-reset"

const resetPasswordAPI = async (token: string, newPassword: string) => {
  const data = { password_reset_token: token, new_password: newPassword }
  const url = `https://api.awakeboards.com/v1/account/password/reset`;
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  });
  const json = await response.json();
  return response.status === 200 ? Promise.resolve() : Promise.reject(json);
}

const validatePasswordResetTokenAPI = async (token: string) : Promise<string> => {
  const url = `https://api.awakeboards.com/v1/account/password/reset/validate_token/${token}`;
  const response = await fetch(url, {
    method: 'GET',
  });
  const json = await response.json();
  return response.status === 200 ? Promise.resolve(json?.email) : Promise.reject(json);
}

export default function ResetPassword() {
  let params = useParams();

  useEffect(() => {
    if (params && params?.passwordResetToken) {
      console.log("token");
      validatePasswordResetTokenAPI(params.passwordResetToken).then((email) => {
        setEmail(email);
      }).catch(() => {
        console.log("Expired or invalid token");
        window.location.replace(ERROR_REDIRECT_URL);
      })
    }
  }, [params])

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");

  function validateForm() {
    return password2.length > 7 && password.length > 7 && password === password2;
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    resetPasswordAPI(params?.passwordResetToken || "", password).then(() => {
      console.log("OK")
      window.location.replace(SUCCESS_REDIRECT_URL);
    }).catch((e) => console.log("Not ok", e));
  }

  return (
    <div className={'d-flex justify-content-center align-items-center'} style={{ minHeight: "100vh" }}>
      <div className={'shadow m-3 mb-5'}
           style={{ paddingLeft: 40, paddingRight: 40, paddingTop: 30, paddingBottom: 40, borderRadius: 6 }}>
        <h5 className={'mb-4'}>Reset your Awake Rider Account password</h5>
        <Form onSubmit={handleSubmit}>
          <p>Enter a new password for <b>{email}</b></p>
          <Form.Group controlId="password" className={""}>
            <Form.Label hidden={true}>New Password</Form.Label>
            <Form.Control
              type="email"
              value={email}
              readOnly={true}
              hidden={true}
              autoComplete="username"
            />
          </Form.Group>
          <Form.Group controlId="password" className={"mb-2"}>
            <Form.Label>New Password</Form.Label>
            <Form.Control
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={"Chose a new password (min length 8)"}
              autoComplete="new-password"
            />
          </Form.Group>
          <Form.Group controlId="password2" className={"mb-4"}>
            <Form.Label>Confirm Password</Form.Label>
            <Form.Control
              type="password"
              value={password2}
              onChange={(e) => setPassword2(e.target.value)}
              placeholder={"Repeat password"}
              autoComplete="new-password"
            />
          </Form.Group>
          <Form.Group className="d-grid gap-2">
            <Button variant="secondary" disabled={!validateForm()} type='submit'>
              Confirm
            </Button>
          </Form.Group>
        </Form>
      </div>

    </div>
  );
}
