import React from 'react';
import logo from './logo.svg';
import './App.css';
import { Link } from "react-router-dom";

function App() {
  return (
    <div className="App">
      {/*<header className="App-header">*/}
      {/*  <nav*/}
      {/*    style={{*/}
      {/*      borderBottom: "solid 1px",*/}
      {/*      paddingBottom: "1rem",*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    <Link to="/password/reset">Invoices</Link> |{" "}*/}
      {/*  </nav>*/}
      {/*</header>*/}
    </div>
  );
}

export default App;
